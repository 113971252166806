import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { RefetchPlatformData } from '../refetchPlatformData/refetchPlatformData';
import { FilterTypes } from '../../ViewModel/filterViewModel/filterViewModel';
import { TriggeredByOptions } from '../../../../types/types';

export type OnFilterChanged = (
  filter: Partial<Record<FilterTypes, string[]>> | undefined,
) => Promise<void>;

export function createOnFilterChanged(
  {
    getControllerState,
    context: { biLogger, experiments },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  refetchPlatformData: RefetchPlatformData,
): OnFilterChanged {
  return (filter: Parameters<OnFilterChanged>[0]) => {
    const [state, setState] = getControllerState();
    const locationFilterLimitationEnbled = experiments.enabled(
      'specs.bookings.calendar.locationAndStaffLimitaions',
    );

    setState({
      filterOptions: filter
        ? {
            ...state.filterOptions,
            ...filter,
          }
        : {
            [FilterTypes.SERVICE]: [],
            [FilterTypes.LOCATION]: locationFilterLimitationEnbled
              ? state.filterOptions.LOCATION
              : [],
            [FilterTypes.STAFF_MEMBER]: [],
          },
      selectedVariantsOptions: [],
    });

    return refetchPlatformData(TriggeredByOptions.FILTER_CHANGED);
  };
}
