import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { Optional } from '../../../../types/types';
import { MemoizedViewModalFactory } from '../viewModel';
import { getPaymentDescription } from '../../../../utils/payment/payment';
import { isCalendarPage } from '../../../../utils/presets';

export type ServiceSelection = {
  id: string;
  slug: string;
  name: string;
  price: string;
};

export type ServiceSelectionViewModel = {
  ctaLabel: string;
  services: ServiceSelection[];
};

export const memoizedServiceSelectionViewModel: MemoizedViewModalFactory<
  Optional<ServiceSelectionViewModel>
> = {
  dependencies: {
    state: ['availableServices'],
  },
  createViewModel: createServiceSelectionViewModel,
};

export function createServiceSelectionViewModel({
  context,
  state,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<ServiceSelectionViewModel> {
  const { t, wixSdkAdapter, preset } = context;
  const { rescheduleBookingDetails, availableServices } = state;

  const shouldCreateViewModel =
    isCalendarPage(preset) &&
    wixSdkAdapter.isBackFromFormWithCart() &&
    !rescheduleBookingDetails &&
    availableServices.length > 1;

  if (!shouldCreateViewModel) {
    return;
  }

  const ctaLabel = t('app.change-service.text');
  const services: ServiceSelection[] = availableServices
    .slice(1)
    .map((service) => ({
      name: service.info.name,
      price: getPaymentDescription({
        context,
        service,
      }),
      id: service.id,
      slug: service.info.slugs[0],
    }))
    .sort((service1, service2) => service1.name.localeCompare(service2.name));

  return {
    ctaLabel,
    services,
  };
}
